<template>
  <div>
    <b-card bg-variant="Success" class="text-center" v-if="submitted">
        <b-button
        variant="outline-primary"
        :to="{ name: 'crm-utenti-index' }"
        >
            <feather-icon
                icon="CornerUpLeftIcon"
                size="16"
                class="mr-1"
            />Lista Utenti
        </b-button>
    </b-card>

    <b-card border-variant="danger" v-if="errore_caricamento">
      <b-row class="match-height">
        <b-col
          md="8"
        >
          <h4 class="mt-0 mb-0 text-danger">Errore durante il caricamento delle informazioni iniziali</h4>
          <p>Ti preghiamo di segnalare il problema all'assistenza tecnica, grazie</p>
        </b-col>
        <b-col 
          md="4"
          class="text-center"
        >
          <b-button
          variant="primary"
          :to="{ name: 'crm-ricerche-index' }"
          >
              <feather-icon
                  icon="CornerUpLeftIcon"
                  size="16"
                  class="mr-1"
              />Lista Ricerche
          </b-button>
        </b-col>
      </b-row>
        
    </b-card>
    
    <div class="text-center my-3" v-if="Caricato == false">
        <b-spinner
            variant="primary"
            style="width: 3rem; height: 3rem;"
            class="mr-1"
            label="loading"
        />
    </div>

    <div v-if="Caricato">
        <validation-observer ref="simpleRules">
            <b-form>
              <b-card bg-variant="Default" title="Modifica Ricerca">
                <b-row>
                  <b-col md="12" lg="6">
                    <h4>{{ this.campiform.customer.company_name }}</h4>
                  </b-col>

                  <b-col md="12" lg="6">
                    <h4 class="pb-0 mb-0">Licenza n. {{ this.campiform.licenza.ref_code }}</h4>
                    tipo di licenza: {{ this.campiform.licenza.type }}
                  </b-col>

                </b-row>

                <div class="mt-2">
                    <div md="12" v-if="errore_caricamento_dati_select === true">
                      <h4>Errore nel caricamento dei dati,<br />prego segnalare problema alla nostra assistenza tecnica</h4>
                    </div>

                    <div v-else>
                      <b-tabs pills>
                        <b-tab active>
                          <template #title>
                            <feather-icon
                              icon="AlignJustifyIcon"
                              size="16"
                              class="mr-0 mr-sm-50"
                            />
                            <span class="d-none d-sm-inline">Dettagli</span>
                          </template>
                          <b-card bg-variant="Default" body-class="px-0">
                            <b-row>
                              <b-col md="12" lg="6">
                                <b-form-group label="Obiettivo *" label-for="objective">
                                    <validation-provider
                                    #default="{ errors }"
                                    name="Obiettivo"
                                    rules="required"
                                    >
                                    <b-form-input
                                        v-model="campiform.research.objective"
                                        :state="errors.length > 0 ? false:null"
                                        placeholder=""
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                              </b-col>
                              
                              <b-col md="12" lg="3">
                                <b-form-group label="Clienti da raggiungere *" label-for="province_id">
                                  <b-form-select
                                      v-model="campiform.research.id_type_client"
                                      :options="options_typeClients"
                                  />
                                </b-form-group>
                              </b-col>

                              <b-col md="12" lg="3">
                                <b-form-group label="Status della nuova ricerca *" label-for="id_state">
                                  <validation-provider
                                  #default="{ errors }"
                                  name="Requisiti"
                                  rules="required"
                                  >
                                    <b-form-select
                                        v-model="campiform.research.id_state"
                                        :options="options_state"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                  </validation-provider>
                                </b-form-group>
                              </b-col>

                              <b-col cols="12" lg="6">
                                <b-form-group
                                  label="Profilo Primario *"
                                  label-for="v-adse-profilo"
                                >
                                  <validation-provider
                                  #default="{ errors }"
                                  name="Profilo Primario"
                                  rules="required"
                                  >
                                    <multiselect
                                      v-model="campiform.relazioni.profilo_primario"
                                      :options="options_Profiles"
                                      :custom-label="multiSelect_act" 
                                      placeholder="seleziona o ricerca"
                                      :multiple="false"
                                      :close-on-select="true" 
                                      :clear-on-select="false"
                                      :preserve-search="true"
                                      :show-labels="false"
                                      :max-height="250"
                                      :max="1"
                                      group-values="items"
                                      group-label="groupname" 
                                      :group-select="true" 
                                      label="profilo" 
                                      track-by="text" 
                                    >
                                      <template #optiongroup="slotProps">
                                        <div>{{ slotProps.option.label }}</div>
                                      </template>
                                      <span slot="noResult">Nessun risultato</span>
                                      <span slot="maxElements">Limite opzioni selezionabili raggiunto</span>
                                    </multiselect>
                                    <small class="text-danger">{{ errors[0] }}</small>
                                  </validation-provider>
                                </b-form-group>
                              </b-col>

                              <b-col cols="12" lg="6">
                                <b-form-group
                                  label="Profilo Secondario *"
                                  label-for="v-adse-profilo-secondio"
                                >
                                  <validation-provider
                                  #default="{ errors }"
                                  name="Profilo Secondario"
                                  rules="required"
                                  >
                                    <multiselect
                                      v-model="campiform.relazioni.profilo_secondario"
                                      :options="options_Profiles"
                                      :custom-label="multiSelect_act" 
                                      placeholder="seleziona o ricerca"
                                      :multiple="false"
                                      :close-on-select="true" 
                                      :clear-on-select="false"
                                      :preserve-search="true"
                                      :show-labels="false"
                                      :max-height="250"
                                      :max="1"
                                      group-values="items"
                                      group-label="groupname" 
                                      :group-select="true" 
                                      label="profilo" 
                                      track-by="text" 
                                    >
                                      <template #optiongroup="slotProps">
                                        <div>{{ slotProps.option.label }}</div>
                                      </template>
                                      <span slot="noResult">Nessun risultato</span>
                                      <span slot="maxElements">Limite opzioni selezionabili raggiunto</span>
                                    </multiselect>
                                    <small class="text-danger">{{ errors[0] }}</small>
                                  </validation-provider>
                                </b-form-group>
                              </b-col>

                              <b-col cols="12" lg="6">
                                <b-form-group
                                  label="Settore Principale *"
                                  label-for="v-adse-settore-principale"
                                >
                                  <validation-provider
                                  #default="{ errors }"
                                  name="Settore Principale"
                                  rules="required"
                                  >
                                    <multiselect
                                      v-model="campiform.relazioni.settore_primario"
                                      :options="options_Sectors"
                                      :custom-label="multiSelect_act" 
                                      placeholder="seleziona o ricerca"
                                      :multiple="false"
                                      :close-on-select="true" 
                                      :clear-on-select="false"
                                      :preserve-search="true"
                                      :show-labels="false"
                                      :max-height="250"
                                      :max="1"
                                      group-values="items"
                                      group-label="groupname" 
                                      :group-select="true" 
                                      label="profilo" 
                                      track-by="text" 
                                    >
                                      <template #optiongroup="slotProps">
                                        <div>{{ slotProps.option.label }}</div>
                                      </template>
                                      <span slot="noResult">Nessun risultato</span>
                                      <span slot="maxElements">Limite opzioni selezionabili raggiunto</span>
                                    </multiselect>
                                    <small class="text-danger">{{ errors[0] }}</small>
                                  </validation-provider>
                                </b-form-group>
                              </b-col>

                              <b-col cols="12" lg="6">
                                <b-form-group
                                  label="Settori Collaterali *"
                                  label-for="v-adse-settori-collaterali"
                                >
                                  <validation-provider
                                  #default="{ errors }"
                                  name="Settori Collaterali"
                                  rules="required"
                                  >
                                    <multiselect
                                      v-model="campiform.sectors"
                                      :options="options_Sectors"
                                      :custom-label="multiSelect_act" 
                                      placeholder="seleziona o ricerca"
                                      :multiple="true"
                                      :close-on-select="false" 
                                      :clear-on-select="false"
                                      :preserve-search="true"
                                      :show-labels="false"
                                      :max-height="250"
                                      :max="5"
                                      group-values="items"
                                      group-label="groupname" 
                                      :group-select="true" 
                                      label="profilo" 
                                      track-by="text" 
                                    >
                                      <template #optiongroup="slotProps">
                                        <div>{{ slotProps.option.label }}</div>
                                      </template>
                                      <span slot="noResult">Nessun risultato</span>
                                      <span slot="maxElements">Limite opzioni selezionabili raggiunto</span>
                                    </multiselect>
                                    <small class="text-danger">{{ errors[0] }}</small>
                                  </validation-provider>
                                </b-form-group>
                              </b-col>

                              <b-col md="12" lg="6">
                                <b-form-group
                                  label="Area Geografica *"
                                  label-for="v-adse-area-geografica"
                                >
                                  <validation-provider
                                  #default="{ errors }"
                                  name="Area Geografica *"
                                  rules="required"
                                  >
                                    <multiselect
                                      v-model="campiform.areas"
                                      :options="options_AreaGeografica"
                                      :custom-label="multiSelect_act" 
                                      placeholder="seleziona o ricerca"
                                      :multiple="true"
                                      :close-on-select="false" 
                                      :clear-on-select="false"
                                      :preserve-search="true"
                                      :show-labels="false"
                                      :max-height="250"
                                      :max="40"
                                      group-values="items"
                                      group-label="groupname" 
                                      :group-select="true" 
                                      label="profilo" 
                                      track-by="text" 
                                    >
                                      <template #optiongroup="slotProps">
                                        <div>{{ slotProps.option.label }}</div>
                                      </template>
                                      <span slot="noResult">Nessun risultato</span>
                                      <span slot="maxElements">Limite opzioni selezionabili raggiunto</span>
                                    </multiselect>
                                    <small class="text-danger">{{ errors[0] }}</small>
                                  </validation-provider>
                                </b-form-group>
                              </b-col>

                              <b-col cols="12" lg="3">
                                <b-card-text class="mb-0">Esperienza Vendita *</b-card-text>
                                <b-form-checkbox
                                  checked="false"
                                  name="check-button"
                                  switch
                                  id="v-campiform-sales_experience"
                                  v-model="campiform.research.sales_experience"
                                ></b-form-checkbox>
                              </b-col>

                              <b-col cols="12" lg="3">
                                <b-card-text class="mb-0">Esperienza Settore *</b-card-text>
                                <b-form-checkbox
                                  checked="false"
                                  name="check-button"
                                  switch
                                  id="v-campiform-sector_experience"
                                  v-model="campiform.research.sector_experience"
                                ></b-form-checkbox>
                              </b-col>

                              <b-col cols="12" lg="6">
                                <b-form-group
                                  label="Offerta *"
                                  label-for="v-adse-offerta"
                                >
                                  <validation-provider
                                  #default="{ errors }"
                                  name="Offerta"
                                  rules="required"
                                  >
                                    <multiselect
                                      v-model="campiform.offerts"
                                      :options="options_Offerts"
                                      :custom-label="multiSelect_act" 
                                      placeholder="seleziona o ricerca"
                                      :multiple="true"
                                      :close-on-select="false" 
                                      :clear-on-select="false"
                                      :preserve-search="true"
                                      :show-labels="false"
                                      :max-height="250"
                                      :max="5"
                                      group-values="items"
                                      group-label="groupname" 
                                      :group-select="true" 
                                      label="profilo" 
                                      track-by="text" 
                                    >
                                      <template #optiongroup="slotProps">
                                        <div>{{ slotProps.option.label }}</div>
                                      </template>
                                      <span slot="noResult">Nessun risultato</span>
                                      <span slot="maxElements">Limite opzioni selezionabili raggiunto</span>
                                    </multiselect>
                                    <small class="text-danger">{{ errors[0] }}</small>
                                  </validation-provider>
                                </b-form-group>
                              </b-col>

                              <b-col cols="12" lg="6">
                                <b-form-group
                                  label="Requisiti *"
                                  label-for="v-adse-requisiti"
                                >
                                  <validation-provider
                                  #default="{ errors }"
                                  name="Requisiti"
                                  rules="required"
                                  >
                                    <multiselect
                                      v-model="campiform.requirements"
                                      :options="options_Requirements"
                                      :custom-label="multiSelect_act" 
                                      placeholder="seleziona o ricerca"
                                      :multiple="true"
                                      :close-on-select="false" 
                                      :clear-on-select="false"
                                      :preserve-search="true"
                                      :show-labels="false"
                                      :max-height="250"
                                      :max="10"
                                      label="requisiti" 
                                      track-by="text" 
                                    >
                                      <template #optiongroup="slotProps">
                                        <div>{{ slotProps.option.label }}</div>
                                      </template>
                                      <span slot="noResult">Nessun risultato</span>
                                      <span slot="maxElements">Limite opzioni selezionabili raggiunto</span>
                                    </multiselect>
                                    <small class="text-danger">{{ errors[0] }}</small>
                                  </validation-provider>
                                </b-form-group>
                              </b-col>
                              
                              <b-col md="12" lg="6">
                                <b-form-group label="Prodotti e Servizi *" label-for="products_services">
                                    <validation-provider
                                    #default="{ errors }"
                                    name="Prodotti e Servizi"
                                    rules="required"
                                    >
                                    <b-form-input
                                        v-model="campiform.research.products_services"
                                        :state="errors.length > 0 ? false:null"
                                        placeholder=""
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                              </b-col>

                              <b-col cols="12" lg="6">
                                <b-form-group
                                  label="Competitors"
                                  label-for="v-adse-competitors"
                                >
                                <multiselect 
                                    v-model="campiform.competitors" 
                                    id="competitors" 
                                    label="competitors" 
                                    track-by="value" 
                                    placeholder="ricerca" 
                                    open-direction="bottom" 
                                    :options="lista_aziende" 
                                    :multiple="true" 
                                    :searchable="true" 
                                    :loading="isLoadingAziende" 
                                    :internal-search="true" 
                                    :clear-on-select="false" 
                                    :close-on-select="false" 
                                    :options-limit="10"
                                    :max-height="250" 
                                    :show-no-results="false" 
                                    :hide-selected="false" 
                                    :show-labels="false" 
                                    :custom-label="multiSelect_act" 
                                    @search-change="asyncFindAziende">
                                      <span slot="maxElements">Limite opzioni selezionabili raggiunto</span>
                                      <span slot="noResult">Nessun risultato</span>
                                  </multiselect>
                                </b-form-group>
                              </b-col>

                              <b-col cols="12">
                                <b-form-group label="Note *" label-for="note">
                                  <b-form-textarea 
                                    id="textarea-note"
                                    rows="3"
                                    v-model="campiform.research.note"
                                  />
                                </b-form-group>
                              </b-col>
                            
                            </b-row>

                          </b-card>
                        </b-tab>

                        <b-tab>
                          <template #title>
                            <feather-icon
                              icon="AlignJustifyIcon"
                              size="16"
                              class="mr-0 mr-sm-50"
                            />
                            <span class="d-none d-sm-inline">Annuncio</span>
                          </template>
                          <b-card bg-variant="Default" body-class="px-0">
                            <b-row>
                              <b-col md="12" lg="12">
                                <b-form-group label="Titolo *" label-for="title_adv">
                                    <validation-provider
                                    #default="{ errors }"
                                    name="Titolo"
                                    rules="required"
                                    >
                                    <b-form-input
                                        v-model="campiform.research.title_adv"
                                        :state="errors.length > 0 ? false:null"
                                        placeholder=""
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                              </b-col>
                              <b-col md="12" lg="12">
                                <b-form-group
                                  label="Testo Ricerca"
                                  label-for="task-description"
                                >
                                  <validation-provider
                                  #default="{ errors }"
                                  name="Testo Ricerca"
                                  rules="required"
                                  >
                                    <quill-editor
                                      id="quil-content"
                                      v-model="campiform.research.body_adv"
                                      :options="editorOption"
                                      class="border-bottom-0"
                                      toolbar="minimal"
                                    />
                                  <small class="text-danger">{{ errors[0] }}</small>
                                  </validation-provider>
                                </b-form-group>
                              </b-col>
                              <b-col md="12" lg="12">
                                <b-button
                                  variant="secondary"
                                  type="submit"
                                  @click.prevent="testoAnnuncioAutomatico()"
                                >Genera automaticamente</b-button>
                              </b-col>

                            </b-row>

                          </b-card>
                        </b-tab>

                        <b-tab>
                          <template #title>
                            <feather-icon
                              icon="AlignJustifyIcon"
                              size="16"
                              class="mr-0 mr-sm-50"
                            />
                            <span class="d-none d-sm-inline">Email Marketing</span>
                          </template>
                          <b-card bg-variant="Default" body-class="px-0">
                            <b-row>
                              <b-col md="12" lg="6">
                                <b-form-group label="Oggetto *" label-for="object_email">
                                    <validation-provider
                                    #default="{ errors }"
                                    name="Oggetto"
                                    rules="required"
                                    >
                                    <b-form-input
                                        v-model="campiform.research.object_email"
                                        :state="errors.length > 0 ? false:null"
                                        placeholder=""
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                              </b-col>
                              <b-col md="12" lg="6">
                                <b-form-group label="Placeholder *" label-for="placeholder_email">
                                    <validation-provider
                                    #default="{ errors }"
                                    name="Placeholder"
                                    rules="required"
                                    >
                                    <b-form-input
                                        v-model="campiform.research.placeholder_email"
                                        :state="errors.length > 0 ? false:null"
                                        placeholder=""
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                              </b-col>
                              <b-col md="12" lg="12">
                                <b-form-group
                                  label="Corpo"
                                  label-for="body_email"
                                >
                                  <validation-provider
                                  #default="{ errors }"
                                  name="Corpo email"
                                  rules="required"
                                  >
                                    <quill-editor
                                      id="quil-content"
                                      v-model="campiform.research.body_email"
                                      :options="editorOption"
                                      class="border-bottom-0"
                                      toolbar="minimal"
                                    />
                                  <small class="text-danger">{{ errors[0] }}</small>
                                  </validation-provider>
                                </b-form-group>
                              </b-col>
                              <b-col md="12" lg="12">
                                <b-button
                                  variant="secondary"
                                  type="submit"
                                  @click.prevent="testoEmailAutomatico()"
                                >Genera automaticamente</b-button>
                              </b-col>


                            </b-row>
                          </b-card>
                        </b-tab>

                      </b-tabs>

                      <div md="12" class="text-center">
                        <b-button
                          variant="primary"
                          type="submit"
                          :disabled="action_press"
                          @click.prevent="validationForm()"
                        >
                          <div v-if="action_press">Salvaggio in corso <b-spinner small class="ml-1" /></div>
                          <div v-else>Salva</div> 
                        </b-button>
                      </div>
                    </div>
                    
                  
                </div>
              </b-card>

            </b-form>
        </validation-observer>
    </div>
      
  </div>
</template>

<script>
import router from '@/router'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import it from 'vee-validate/dist/locale/it.json'
import { BTabs, BTab, BRow, BCol, BCard, BCardText, BSpinner, BButton, BFormGroup, BForm, BInputGroup, BFormInput, BFormCheckbox, BFormTextarea, BInputGroupPrepend, BInputGroupAppend, BDropdownDivider, BFormSelect, BListGroup, BListGroupItem } from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  required, email, digits, alphaDash, length, min
} from '@validations'
import moment from 'moment'
import { prezzoFormattato } from '@core/utils/funzioniGlobali' 
import Multiselect from 'vue-multiselect' 

// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    it,
    BTabs,
    BTab,
    BRow,
    BCol,
    BCard, 
    BSpinner, 
    BCardText, 
    BButton, 
    BForm,
    BInputGroup,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BFormTextarea,
    BInputGroupPrepend,
    BInputGroupAppend,
    BDropdownDivider,
    BFormSelect,
    BListGroup, 
    BListGroupItem, 

    Multiselect,
    quillEditor,

    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,     
  },
  data() {
    return {
        errore_caricamento_dati_select: false,
      valoreDefaultAzienda: '',
      editorOption: {
        theme: 'snow',
        toolbar: 'minimal'
      },
      campiform: {
        research: {
          id_customer: '', 
          id_license: '', 
          id_state: '',
          objective: '',
          id_primary_profile: '', 
          id_secondary_profile: '',
          id_primary_sector: '',
          sales_experience: false,
          sector_experience: false,
          id_type_client: '',
          products_services: '',
          note: '',
          title_adv: '',
          body_adv: '',
          object_email: '',
          placeholder_email: '',
          body_email: '',
        },
        areas: [ ],
        sectors: [ ],
        competitors: [ ],
        offerts: [ ],
        requirements: [ ],
      },
      options_rating: [
        { value: 1, text: '1' },
        { value: 2, text: '2' },
        { value: 3, text: '3' },
        { value: 4, text: '4' },
        { value: 5, text: '5' },
      ],
      required,
      email,
      alphaDash, 
      digits,
      length,
      min,
      
      Caricato: false,
      errore_caricamento: false,
      submitted: false,
      action_press: false,
      lista_aziende: [],
      isLoadingAziende: false,

      filteredOptions: [],
      isTyping: false,
      limit: 10,  
      
      lista_licenze: [],

      options_state : [{
        text: 'Da Confermare',
        value: 1
      },{
        text: 'Attiva',
        value: 2
      }],
      options_Aziende : [ ],
      options_typeClients : [ ],
      options_Profiles: [ ], 
      options_Sectors: [ ],
      options_Offerts: [ ],
      options_Requirements: [ ],
      options_AreaGeografica: [ ],

    }
  },
  created() {
    //errori validazione in italiano
    localize({
      it: {
        messages: it.messages,
      },
    })

    //estrazione dati per popolare le select secce (Offerts, Requirements, ecc.)
    const typeClientslangPromise = this.$http.get('api/crm/research/select/clientetipo')
    const ProfiloPromise = this.$http.get('api/crm/agents/select/profilo')
    const SettoriPromise = this.$http.get('api/crm/agents/select/settore')
    const OffertsPromise = this.$http.get('api/crm/research/select/offerte')
    const RequirementsPromise = this.$http.get('api/crm/research/select/requisiti')
    const RequirementsRegioniProvince = this.$http.get('api/fs/regionsprovinces/list')
    const schedaAgenteBase = this.$http.get('api/crm/research/schedabaseedit/'+router.currentRoute.params.id_riga)

    Promise.all([typeClientslangPromise,ProfiloPromise,SettoriPromise,OffertsPromise,
    RequirementsPromise,RequirementsRegioniProvince,schedaAgenteBase]).then(response => {
        if(response[0].data.statusCode===200){
            //caricamento lista Lingue
            this.options_typeClients = response[0].data.reply

            if(response[1].data.statusCode===200){
            //caricamento lista Profiles
            this.options_Profiles = response[1].data.reply

            if(response[2].data.statusCode===200){
                //caricamento lista Profiles
                this.options_Sectors = response[2].data.reply

                if(response[3].data.statusCode===200){
                //caricamento lista Offerts
                this.options_Offerts = response[3].data.reply

                if(response[4].data.statusCode===200){
                    //caricamento lista Requirements
                    //console.log(response[4].data.reply);

                    this.options_Requirements = response[4].data.reply

                    if(response[5].data.statusCode===200){
                        //caricamento lista Area Geografica (regioni e province)
                        //console.log(response[5].data.reply);

                        this.options_AreaGeografica = response[5].data.reply

                        if(response[6].data.statusCode===200){
                            //caricamento info scheda;

                            this.campiform = response[6].data.reply

                            console.log(this.campiform)

                            this.Caricato = true;
                            this.errore_caricamento = false;
                            this.errore_caricamento_dati_select = false;
                        } else {
                            this.Caricato = false;
                            this.errore_caricamento_dati_select = true;
                        }

                    } else {
                        this.Caricato = false;
                        this.errore_caricamento_dati_select = true;
                    }

                } else {
                    this.Caricato = false;
                    this.errore_caricamento_dati_select = true;
                }

                } else {
                this.Caricato = false;
                this.errore_caricamento_dati_select = true;
                }

            } else {
                this.Caricato = false;
                this.errore_caricamento_dati_select = true;
            }

            } else {
            this.Caricato = false;
            this.errore_caricamento_dati_select = true;
            }          
        } else {
            this.Caricato = false;
            this.errore_caricamento_dati_select = true;
        }
    })
    
  },
  methods: {
    multiSelect_act ({ value, text }) {
      return `${text}`
    },
    formattazioneOrario(value, stringa){
      return moment(String(value)).locale('it').format(stringa)
    },
    asyncFindAziende(query_aziende) {
      console.log("asyncFindAziende ...");
      let aziende_ricerca_ok = query_aziende.trim();

      if((aziende_ricerca_ok !== '') && (aziende_ricerca_ok.length >= 3)){    
        //si parte
        this.isLoadingAziende = true
        
        //azzero le option dell'autosuggest dei cap
        this.lista_aziende = [ ];

        this.$http.get('api/crm/agents/select/azienda/'+aziende_ricerca_ok).then(response => { 
          if((response.data.statusCode>=200) && (response.data.statusCode<=299)){
            //risposta positiva
            this.isLoadingAziende = false;

            this.lista_aziende = response.data.reply;
            //console.log(this.lista_aziende)
          } else {
            //risposta negativa (errore sul server)
            if(response.data.reply.message != ''){
              this.$swal({
                  icon: 'warning',
                  title: 'Si è verificato un errore',
                  text: 'nella ricerca delle aziende',
                  customClass: {
                  confirmButton: 'btn btn-outline-primary',
                  },
              })
            } else {
              this.$swal({
                  icon: 'error',
                  title: 'Si è verificato un errore generico',
                  text: 'operazione non riuscita, ti preghiamo di contattate l\'assistenza tecnica per segnalare il problema',
                  customClass: {
                  confirmButton: 'btn btn-outline-primary',
                  },
              })
            }

            this.isLoadingAziende = false;
          }
        }).catch(e => {
          console.log(e);
          this.isLoadingAziende = false;
        });
      }

    },
    testoAnnuncioAutomatico(){
      let testo_pre_caricato ="Inserisci qui il tuo Teaser<br /><h1>INSERISCI QUI LA TUA HEADLINE</h1><h2>Inserisci qui la tua Sub-headline</h2><p><strong>Chi siamo:</strong></p><p>&nbsp;</p><p>&nbsp;</p><p><strong>Requisiti graditi:</strong></p><p>Gradita esperienza di vendita<br />Gradita esperienza di settore<br />Richiesta residenza sul territorio indicato</p><p>&nbsp;</p><p><strong>Fascia di reddito:</strong><br />25000€ - 100000€</p><p><strong><u>Ricerca pubblicata da agentscout.it</u><br />La ricerca è rivolta ad ambo i sessi, si prega di indicare nel curriculum l'esperienza di vendita e di settore in modo esplicito anche se minima, saranno prese in considerazione solo le candidature provenienti da questa pagina</strong></p>";
      
      this.campiform.research.body_adv = testo_pre_caricato;
    },
    testoEmailAutomatico(){
      let testo_pre_caricato ="<p>Selezioniamo per nostro cliente</p><p>1 agente di commercio energia</p><p>Si Offre contratto a norma di legge con relativi bonus</p>";
      
      this.campiform.research.body_email = testo_pre_caricato;
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          // eslint-disable-next-line
          // console.log('start chiamata api per salvataggio generale ... dopo allineamento variabili')

          //conversione per salvataggio automatizzato su laravel
          let id_primary_profile_OK = this.campiform.research.id_primary_profile.value;
          this.campiform.research.id_primary_profile = id_primary_profile_OK;
          
          let id_secondary_profile_OK = this.campiform.research.id_secondary_profile.value;
          this.campiform.research.id_secondary_profile = id_secondary_profile_OK;

          let id_primary_sector_OK = this.campiform.research.id_primary_sector.value;
          this.campiform.research.id_primary_sector = id_primary_sector_OK;

          console.log(this.campiform);

          this.$http.post('api/crm/research/saveedit', 
            this.campiform 
            ).then(response => { 
              console.log("risposta server --------")
              console.log(response.data)

              if((response.data.statusCode>=200) && (response.data.statusCode<=299)){
                //risposta positiva
                this.$router.replace('/crm/ricerche')
                .then(() => {
                  this.$swal({
                      icon: 'success',
                      title: 'Ricerca aggiornata correttamente',
                      confirmButtonText: 'chiudi',
                      customClass: {
                      confirmButton: 'btn btn-success',
                      },
                  })
                })
                
              } else {
                //risposta negativa (errore sul server)
                this.$swal({
                    icon: 'error',
                    title: 'Si è verificato un errore',
                    text: 'operazione non riuscita, ti preghiamo di contattate l\'assistenza tecnica per segnalare il problema',
                    customClass: {
                    confirmButton: 'btn btn-outline-primary',
                    },
                })
              }
            }).catch(e => {
              console.log(e);
            });

        }
      })
    },

  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-sweetalert.scss';
@import '@core/scss/vue/libs/vue-multiselect.min.scss';
</style>